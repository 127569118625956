import { useMemo, useCallback } from 'react';
import { useMutation } from 'react-query';
import get from 'lodash/get';
import set from 'lodash/set';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import ApiBase from '../../core/ApiBase.js';
import useService from './useService.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
function useCrud(_a) {
  var _b = _a, {
    methodUrl,
    instanceKey,
    instance,
    updateInstance,
    onMutationSuccess,
    instanceIdKey = "id"
  } = _b, rest = __objRest(_b, [
    "methodUrl",
    "instanceKey",
    "instance",
    "updateInstance",
    "onMutationSuccess",
    "instanceIdKey"
  ]);
  const { t } = useTranslation();
  const api = useService(ApiBase);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const instanceId = useMemo(() => get(instance, instanceIdKey, null), [instance, instanceIdKey]);
  const createOrUpdate = useMutation(
    (data) => api.createOrUpdate(methodUrl, instanceKey ? set({}, instanceKey, data) : data, instanceId),
    __spreadValues({
      onSuccess: (res) => {
        const data = instanceKey ? get(res, instanceKey, res) : res;
        updateInstance == null ? void 0 : updateInstance(data, instanceId);
        onMutationSuccess == null ? void 0 : onMutationSuccess(data, instanceId);
        enqueueSnackbar(t("appMessages.actionSubmitted"), { variant: "success" });
      }
    }, rest)
  );
  const deleteMutation = useMutation(
    () => {
      if (instanceId != null) {
        return api.delete(`${methodUrl}/${instanceId}`);
      }
      return null;
    },
    {
      onSuccess: () => {
        updateInstance == null ? void 0 : updateInstance(null, instanceId);
        onMutationSuccess == null ? void 0 : onMutationSuccess(null, instanceId);
        enqueueSnackbar(t("appMessages.deleteSuccess"), { variant: "success" });
      }
    }
  );
  const handleDelete = useCallback(
    (message) => confirm({ description: message != null ? message : t("appMessages.deleteConfirm") }).then(
      deleteMutation.mutate
    ),
    [confirm, deleteMutation.mutate, t]
  );
  return {
    createOrUpdate,
    deleteMutation,
    handleDelete
  };
}

export { useCrud as default };
