import { createAction } from '@reduxjs/toolkit';

const changeFilterItems = (sliceName) => createAction(`${sliceName}/changeFilterItems`);
const addNewFilter = (sliceName) => createAction(`${sliceName}/addNewFilter`);
const updateFilter = (sliceName) => createAction(`${sliceName}/updateFilter`);
const deleteFilter = (sliceName) => createAction(`${sliceName}/deleteFilter`);
const updateOrganizations = (sliceName) => createAction(`${sliceName}/updateOrganizations`);
const clearSearchValue = (sliceName) => createAction(`${sliceName}/clearSearchValue`);

export { addNewFilter, changeFilterItems, clearSearchValue, deleteFilter, updateFilter, updateOrganizations };
