import { createReducer } from '@reduxjs/toolkit';
import { changePage, changeLimit } from './pagination.actions.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const paginateDefaultState = {
  page: 0,
  limit: 25,
  limitOptions: [10, 25, 50]
};
const paginationReducer = (sliceName, initialState) => createReducer(
  __spreadValues(__spreadValues({}, paginateDefaultState), initialState),
  (builder) => {
    builder.addCase(changePage(sliceName), (state, { payload }) => {
      state.page = payload;
    }).addCase(changeLimit(sliceName), (state, { payload }) => {
      state.page = 0;
      state.limit = payload;
    });
  }
);

export { paginateDefaultState, paginationReducer };
