import moment from 'moment';

const formatDate = (date, format = "MMM D, YYYY, h:mm A") => {
  if (date === null) return date;
  return moment.utc(date).local().format(format);
};
const formatUtcDate = (date, format = "MMM D, YYYY, h:mm A") => {
  if (date === null) return date;
  return moment.utc(date).format(format);
};
const formatDateRange = (date, range = "from", format = "YYYY-MM-DDTHH:mm:ss[Z]") => {
  if (date === null) return date;
  date = range === "from" ? date.startOf("day") : date.endOf("day");
  return date.format(format);
};
const formatLocalDateRange = (date, range = "from", format = "YYYY-MM-DDTHH:mm:ss[Z]") => {
  if (date === null) return date;
  date = range === "from" ? date.startOf("day") : date.endOf("day");
  return date.format(format);
};

export { formatDate, formatDateRange, formatLocalDateRange, formatUtcDate };
