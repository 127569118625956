import autoBind from 'auto-bind';
import ApiBase from '../core/ApiBase.js';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class PublicApiMethods {
  constructor(props) {
    __publicField(this, "apiBase");
    this.apiBase = new ApiBase(props);
    autoBind(this);
  }
  async getLatestFiatQuote(currency = "NOK") {
    return this.apiBase.get(`/quotes/${currency.toLowerCase()}/latest`).then((res) => res.price);
  }
  async getTransaction(tx_id) {
    return this.apiBase.get(`/api/v1/transactions`, { id: tx_id }).then((res) => res.transaction);
  }
  async createTransaction(transaction) {
    return this.apiBase.post(`/api/v1/transactions/create_not_safe`, { transaction }).then((res) => res.transaction);
  }
  async getTransactionPaymentUrl(tx_id, params) {
    return this.apiBase.get(`/api/v1/transactions/${tx_id}/url`, params);
  }
  async cancelTransaction(tx_id) {
    return this.apiBase.delete(`/api/v1/transactions/${tx_id}`, { transaction_id: tx_id }).then((res) => res.transaction);
  }
  async submitCriiptoData(tx_id, data) {
    return this.apiBase.post(`/api/v1/transactions/${tx_id}/criipto`, data);
  }
  async onPaymentValidate(id_order) {
    const queryParams = new URLSearchParams({
      id_order
    });
    return this.apiBase.post(`/api/v1/transactions/on_validate?${queryParams.toString()}`);
  }
}

export { PublicApiMethods as default };
