import { createReducer } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { changeFilterItems, addNewFilter, updateFilter, deleteFilter, updateOrganizations, clearSearchValue } from './filter.actions.js';
import { findFilterIndex } from './filter.utils.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const filterDefaultState = {
  filterItems: [],
  organizations: [],
  searchValue: ""
};
const filterReducer = (sliceName, initialState) => createReducer(
  __spreadValues(__spreadValues({}, filterDefaultState), initialState),
  (builder) => {
    builder.addCase(changeFilterItems(sliceName), (state, { payload }) => {
      state.filterItems = payload;
    }).addCase(addNewFilter(sliceName), (state, { payload }) => {
      var _a, _b;
      state.filterItems.push(__spreadProps(__spreadValues({}, payload), {
        id: (_a = payload.id) != null ? _a : v4(),
        value: (_b = payload.value) != null ? _b : void 0
      }));
    }).addCase(updateFilter(sliceName), (state, { payload }) => {
      const index = findFilterIndex(state)(payload);
      if (index === void 0) return;
      state.filterItems[index] = __spreadValues(__spreadValues({}, state.filterItems[index]), payload);
    }).addCase(deleteFilter(sliceName), (state, { payload }) => {
      const index = findFilterIndex(state)(payload);
      if (index === void 0) return;
      state.filterItems.splice(index, 1);
    }).addCase(updateOrganizations(sliceName), (state, { payload }) => {
      state.organizations = payload;
    }).addCase(clearSearchValue(sliceName), (state) => {
      state.searchValue = "";
    });
  }
);

export { filterDefaultState, filterReducer };
