var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class ApiErrorBase extends Error {
  constructor({ message, type, statusCode, details }) {
    super(message);
    __publicField(this, "message");
    __publicField(this, "type");
    __publicField(this, "statusCode");
    __publicField(this, "details");
    this.name = "ApiError";
    this.type = type;
    this.message = message;
    this.statusCode = statusCode;
    this.details = details;
  }
  toString() {
    var _a;
    return ((_a = this.message) == null ? void 0 : _a.toString()) || "Unhandled API error";
  }
}

export { ApiErrorBase };
