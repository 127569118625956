import { useQuery } from 'react-query';
import useService from '../helpers/useService.js';
import cacheKeys from '../../cacheKeys.js';
import PublicApiMethods from '../../services/PublicApiMethods.js';
import TransactionsService from '../../services/TransactionsService.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
function useTransaction(tx_id, options) {
  const transactionsService = useService(TransactionsService);
  const publicApiMethods = useService(PublicApiMethods);
  return useQuery(
    cacheKeys.transactionInfo(tx_id),
    () => {
      if (!tx_id) return void 0;
      if (options == null ? void 0 : options.useUnsafeEndpoint) return publicApiMethods.getTransaction(tx_id);
      return transactionsService.getTransaction(tx_id);
    },
    __spreadValues({
      enabled: !!tx_id
    }, options)
  );
}

export { useTransaction as default };
