import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { changeSortModel } from '../../redux/sorting/sorting.actions.js';
import '@reduxjs/toolkit';
import { useSliceSelector } from './useSliceSelector.js';

function useReduxSorting(sliceName) {
  const { sortModel } = useSliceSelector(sliceName);
  const dispatch = useDispatch();
  const onSortModelChange = useCallback(
    (newModel) => dispatch(changeSortModel(sliceName)(newModel)),
    [sliceName, dispatch]
  );
  const gridSortingProps = useMemo(
    () => ({
      sortModel: mapModelToGrid(sortModel),
      onSortModelChange: (model) => onSortModelChange(mapGridModelToCustom(model)),
      sortingMode: "server",
      sortingOrder: ["asc", "desc"]
    }),
    [sortModel, onSortModelChange]
  );
  const sortingApiParams = useMemo(
    () => sortModel[0] ? {
      sort_by: sortModel[0].field,
      sort_direction: sortModel[0].direction
    } : void 0,
    [sortModel]
  );
  return {
    sortModel,
    onSortModelChange,
    gridSortingProps,
    sortingApiParams
  };
}
const mapGridModelToCustom = (model) => model.map((item) => {
  var _a;
  return {
    field: item.field,
    direction: (_a = item.sort) != null ? _a : "asc"
  };
});
const mapModelToGrid = (model) => model.map((item) => ({
  field: item.field,
  sort: item.direction
}));

export { useReduxSorting };
