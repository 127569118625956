import { getBreakpointValue } from '../theme/breakpoints.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
function responsiveProperty({
  cssProperty,
  min,
  max,
  unit = "rem",
  breakpoints = [600, 960, 1280]
}) {
  const factor = (max - min) / breakpoints[breakpoints.length - 1];
  return breakpoints.reduce(
    (acc, breakpoint) => {
      const value = min + factor * breakpoint;
      return __spreadProps(__spreadValues({}, acc), {
        [`@media (min-width:${breakpoint}px)`]: {
          [cssProperty]: `${Math.round(value * 1e3) / 1e3}${unit}`
        }
      });
    },
    {
      [cssProperty]: `${min}${unit}`
    }
  );
}
function responsiveFontSize(remFontSize, {
  factor = 3,
  breakpoints = ["mobileL", "tabletS", "tablet", "tabletL", "laptopM"],
  unit = "rem"
} = {}) {
  const maxFontSize = typeof remFontSize !== "number" ? parseFloat(remFontSize) : remFontSize;
  const minFontSize = 1 + (maxFontSize - 1) / factor;
  return responsiveProperty({
    max: maxFontSize,
    min: minFontSize,
    cssProperty: "fontSize",
    breakpoints: breakpoints.map(getBreakpointValue),
    unit
  });
}

export { responsiveFontSize, responsiveProperty };
