import autoBind from 'auto-bind';
import axios from 'axios';
import ApiError from './ApiError.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class ApiBase {
  constructor(_a) {
    __publicField(this, "axiosClient");
    var _b = _a, { headers, onUnauthorized } = _b, rest = __objRest(_b, ["headers", "onUnauthorized"]);
    this.axiosClient = axios.create(__spreadValues({
      timeout: 15e3,
      headers: __spreadValues({
        Accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
      }, headers),
      responseType: "json"
    }, rest));
    this.axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const { status, statusText } = error.response;
          const errData = error.response.data;
          let errMsg = `${statusText} (${status})`;
          if (errData && errData.message) errMsg = errData.message;
          else if (errData && errData.error) errMsg = errData.error;
          else if (errData && errData.errors) errMsg = errData.errors;
          const apiErr = new ApiError(errMsg, status);
          if (apiErr.shouldLogout) onUnauthorized == null ? void 0 : onUnauthorized(apiErr);
          throw apiErr;
        }
        if (error.message) {
          throw new ApiError(error.message);
        }
        throw new ApiError("Unhandled error happened...");
      }
    );
    autoBind(this);
  }
  async request(_c) {
    var _d = _c, { fullResponse } = _d, options = __objRest(_d, ["fullResponse"]);
    const response = await this.axiosClient.request(options);
    if (fullResponse) return response;
    return response.data;
  }
  async get(url, params, options) {
    return this.request(__spreadValues({ url, method: "get", params }, options));
  }
  async post(url, body, options) {
    return this.request(__spreadValues({ url, method: "post", data: body }, options));
  }
  async put(url, data, options) {
    return this.request(__spreadValues({ url, method: "put", data }, options));
  }
  async patch(url, data, options) {
    return this.request(__spreadValues({ url, method: "patch", data }, options));
  }
  async delete(url, params, options) {
    return this.request(__spreadValues({ url, method: "delete", data: params }, options));
  }
  async createOrUpdate(url, data, id, options) {
    return this.request(__spreadValues({
      url: url + (id ? `/${id}` : ""),
      method: id ? "put" : "post",
      data
    }, options));
  }
}

export { ApiBase as default };
