import { useQueryClient } from 'react-query';
import useCrud from '../helpers/useCrud.js';
import cacheKeys from '../../cacheKeys.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
function useFeeLevelCrud(instance, crudOptions) {
  const queryClient = useQueryClient();
  return useCrud(__spreadProps(__spreadValues({}, crudOptions), {
    methodUrl: "/fee_levels",
    instanceKey: "fee_level",
    instance,
    updateInstance: async (feeLevel, id) => {
      if (id) await queryClient.setQueryData(cacheKeys.feeLevel(id), feeLevel);
      if (instance == null ? void 0 : instance.user_id) {
        await queryClient.invalidateQueries(cacheKeys.userFeeLevels(instance.user_id));
      } else {
        await queryClient.invalidateQueries(cacheKeys.userFeeLevels("")[0]);
      }
    }
  }));
}

export { useFeeLevelCrud as default };
