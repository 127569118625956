import autoBind from 'auto-bind';
import ApiBase from '../core/ApiBase.js';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class UsersService {
  constructor(props) {
    __publicField(this, "apiBase");
    this.apiBase = new ApiBase(props);
    autoBind(this);
  }
  async getUsers(params) {
    return this.apiBase.get(`/users`, params);
  }
  async getUser(id) {
    return this.apiBase.get(`/users/${id}`).then((res) => res.user);
  }
  async setUserBan(userId, banned, reason) {
    return this.apiBase.post(`/users/${userId}/ban`, {
      banned,
      banned_reason: reason
    }).then((res) => res.user);
  }
  async setUserApproved(userId, approved) {
    return this.apiBase.post(`/users/${userId}/approve`, {
      approved
    }).then((res) => res.user);
  }
}

export { UsersService as default };
