import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { calcChangePercent } from '../../utils/helpers.js';
import useService from '../helpers/useService.js';
import cacheKeys from '../../cacheKeys.js';
import DashboardsService from '../../services/DashboardsService.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
function useDashboardQuery({ userId, params }) {
  const dashboardsService = useService(DashboardsService);
  const cacheKey = userId ? cacheKeys.userDashboard(userId, params) : cacheKeys.adminDashboard(params);
  const apiMethod = () => userId ? dashboardsService.getUserDashboard(userId, params) : dashboardsService.getAdminDashboard(params);
  const _a = useQuery(cacheKey, apiMethod), { data } = _a, queryRest = __objRest(_a, ["data"]);
  const getValue = useCallback(
    function(key, { previous = false } = {}) {
      var _a2, _b, _c;
      if (!data) return void 0;
      let res = (_b = (_a2 = data == null ? void 0 : data.current) == null ? void 0 : _a2[key]) != null ? _b : data == null ? void 0 : data[key];
      if (previous) {
        res = (_c = data == null ? void 0 : data.previous) == null ? void 0 : _c[key];
      }
      return res != null ? parseFloat(res) : void 0;
    },
    [data]
  );
  const getValueChangePercent = useCallback(
    (key) => {
      const curValue = getValue(key);
      const prevValue = getValue(key, { previous: true });
      return calcChangePercent(curValue, prevValue);
    },
    [getValue]
  );
  return __spreadValues({
    data,
    getValue,
    getValueChangePercent
  }, queryRest);
}

export { useDashboardQuery as default };
