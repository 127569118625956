const fontSizes = {
  superSmall: "0.625rem",
  // 10px
  extraSmall: "0.75rem",
  // 12px
  small: "0.875rem",
  // 14px
  default: "1rem",
  // 16px
  medium: "1.125rem",
  // 18px
  large: "1.25rem",
  // 20px
  title: "1.5rem",
  // 24px
  titleLarge: "1.75rem"
  //28px
};
const fontFamilies = {
  default: "Inter, sans-serif",
  title: "Inter, sans-serif"
};
const typography = {
  fontSize: 16,
  fontSizes,
  fontFamily: fontFamilies.default,
  fontFamilies,
  h1: {
    fontWeight: 700,
    fontSize: fontSizes.titleLarge
  },
  h2: {
    fontWeight: 700,
    fontSize: fontSizes.title
  },
  h3: {
    fontWeight: 600,
    fontSize: fontSizes.large
  },
  h4: {
    fontWeight: 400,
    fontSize: fontSizes.large
  },
  h5: {
    fontWeight: 600,
    fontSize: fontSizes.medium
  },
  h6: {
    fontWeight: 400,
    fontSize: fontSizes.medium
  },
  body1: {
    fontSize: fontSizes.default
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: fontSizes.default
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: fontSizes.small
  },
  subtitle3: {
    fontWeight: 400,
    fontSize: fontSizes.extraSmall
  },
  smallCaption: {
    fontWeight: 500,
    fontSize: fontSizes.superSmall
  }
};

export { typography as default, fontFamilies, fontSizes };
