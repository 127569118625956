import { addMethod, number, string } from 'yup';
import moment from 'moment';
import { formatDate } from './formatters/dateFormatters.js';
import 'i18next';

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
}
addMethod(number, "numberNullable", function() {
  return this.nullable().transform(emptyStringToNull);
});
addMethod(string, "phoneNumber", function() {
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  return this.matches(phoneRegExp, "Phone number is not valid");
});
addMethod(
  string,
  "minDate",
  function(limit, errMsg) {
    return this.nullable().test(
      "minDate",
      errMsg != null ? errMsg : `Minimum required date is: ${formatDate(limit, "MMM D, YYYY")}`,
      (value) => !!value && moment(value).isSameOrAfter(limit, "day")
    );
  }
);
addMethod(
  string,
  "maxDate",
  function(limit, errMsg) {
    return this.nullable().test(
      "maxDate",
      errMsg != null ? errMsg : `Maximum allowed date is: ${formatDate(limit, "MMM D, YYYY")}`,
      (value) => !!value && moment(value).isSameOrBefore(limit, "day")
    );
  }
);
addMethod(
  string,
  "minAge",
  function(age, errMsg) {
    return this.nullable().test(
      "minAge",
      errMsg != null ? errMsg : `You must be at least ${age} years old`,
      (value) => !!value && moment(value).isSameOrBefore(moment().subtract({ year: age }), "day")
    );
  }
);
