import { getCurrentLanguage } from '../i18nUtils.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
const fixedDecimals = (num, decimals) => {
  const decimalsPower = 10 ** decimals;
  return Math.round(num * decimalsPower) / decimalsPower;
};
const formatNumber = (num, options) => {
  if (num == null) return null;
  return (+num).toLocaleString(getCurrentLanguage(), options);
};
function formatFloatToFixed(num, _a = {}) {
  var _b = _a, { maximumFractionDigits = 10 } = _b, options = __objRest(_b, ["maximumFractionDigits"]);
  if (num == null) return 0;
  const afterCommaLength = (+num).toFixed(maximumFractionDigits).replace(",", ".").split(".")[0].length;
  let maxDigits = maximumFractionDigits - afterCommaLength;
  if (num < 10) {
    maxDigits += 1;
  }
  return (+num).toLocaleString(getCurrentLanguage(), __spreadValues({
    minimumFractionDigits: 0,
    maximumFractionDigits: Math.min(20, Math.max(0, maxDigits))
  }, options));
}
const fiatCurrencies = ["USD", "EUR", "GBP", "NOK"];
const formatCurrency = (amount, _c = {}) => {
  var _d = _c, { currency = "EUR" } = _d, options = __objRest(_d, ["currency"]);
  if (amount == null) {
    return void 0;
  }
  currency = currency || "EUR";
  const currencyUpperCase = currency.toUpperCase();
  const isFiat = fiatCurrencies.includes(currencyUpperCase);
  if (isFiat) {
    return (+amount).toLocaleString(getCurrentLanguage(), __spreadValues({
      currency: currencyUpperCase,
      style: "currency",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }, options));
  }
  return `${formatNumber(amount, __spreadValues({
    maximumFractionDigits: isFiat ? 2 : 8
  }, options))} ${currencyUpperCase}`;
};

export { fixedDecimals, formatCurrency, formatFloatToFixed, formatNumber };
