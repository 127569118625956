import autoBind from 'auto-bind';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
class AdminApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  // USERS
  getMerchants() {
    return this.api.get(`/merchants`);
  }
  getConnections() {
    return this.api.get(`/auth/connections`);
  }
  getUserProfiles(_a) {
    var _b = _a, {
      merchantIds,
      searchValue
    } = _b, params = __objRest(_b, [
      "merchantIds",
      "searchValue"
    ]);
    const headers = merchantIds && (merchantIds == null ? void 0 : merchantIds.length) > 0 ? {
      "X-Merchant-UUIDs": merchantIds
    } : void 0;
    const queryParams = __spreadValues(__spreadValues({}, params), searchValue ? { email: searchValue } : {});
    return this.api.get("/users", queryParams, {
      headers,
      timeout: 120 * 1e3
    });
  }
  getUser(id) {
    return this.api.get(`/admin/users/${id}`);
  }
  getUsers(params) {
    return this.api.get("/admin/users", params);
  }
  importUsers(params) {
    return this.api.get("/admin/users/csv", params, {
      responseType: "blob",
      timeout: 120 * 1e3
    });
  }
  setUserStatus(id, status) {
    return this.api.post(`/admin/users/${id}/status`, { status });
  }
  banUser(id) {
    return this.api.post(`/admin/users/${id}/ban`);
  }
  unbanUser(id) {
    return this.api.post(`/admin/users/${id}/unban`);
  }
  // TRANSACTIONS
  getTransactionB2C(uuid) {
    return this.api.get(`/admin/invoices/b2c/${uuid}`);
  }
  getTransactionAcquirerDetailsB2C(uuid, paymentId) {
    return this.api.get(
      `/admin/invoices/b2c/${uuid}/payments/${paymentId}/acquirer_tx_data`
    );
  }
  getTransactionAcquirerDetailsB2B(uuid, paymentId) {
    return this.api.get(
      `/admin/invoices/b2b/${uuid}/payments/${paymentId}/acquirer_tx_data`
    );
  }
  getExtraDetailsB2B(uuid, paymentId) {
    return this.api.get(`/admin/invoices/b2b/${uuid}/payments/${paymentId}/extra`);
  }
  getExtraDetailsB2C(uuid, paymentId) {
    return this.api.get(`/admin/invoices/b2c/${uuid}/payments/${paymentId}/extra`);
  }
  getTransactionsB2C(params) {
    return this.api.get("/admin/invoices/b2c", params);
  }
  importTransactionsB2C(params) {
    return this.api.get("/admin/invoices/b2c/csv", params, {
      responseType: "blob",
      timeout: 120 * 1e3
    });
  }
  getTransactionB2B(uuid) {
    return this.api.get(`/admin/invoices/b2b/${uuid}`);
  }
  getTransactionsB2B(_c) {
    var _d = _c, { merchantIds } = _d, params = __objRest(_d, ["merchantIds"]);
    const headers = merchantIds && (merchantIds == null ? void 0 : merchantIds.length) > 0 ? {
      "X-Merchant-UUIDs": merchantIds
    } : void 0;
    return this.api.get("/admin/invoices/b2b", params, {
      headers,
      timeout: 1e3 * 15
    });
  }
  importTransactionsB2B(_e) {
    var _f = _e, {
      merchantIds,
      amount
    } = _f, params = __objRest(_f, [
      "merchantIds",
      "amount"
    ]);
    if (params == null ? void 0 : params.pagesize) {
      delete params.pagesize;
    }
    const headers = merchantIds && (merchantIds == null ? void 0 : merchantIds.length) > 0 ? {
      "X-Merchant-UUIDs": merchantIds
    } : {};
    return this.api.get(
      "/admin/invoices/b2b/csv",
      __spreadProps(__spreadValues({}, params), { amount }),
      {
        responseType: "blob",
        timeout: 180 * 1e3,
        headers
      }
    );
  }
  approveTransactionB2C(id) {
    return this.api.post(`/admin/invoices/b2c/${id}/approve`);
  }
  rejectTransactionB2C(id) {
    return this.api.post(`/admin/invoices/b2c/${id}/cancel`);
  }
  // API KEYS
  getApiKeys(params) {
    return this.api.get("/admin/keys", params);
  }
  deleteApiKey(id) {
    return this.api.delete(`/admin/keys/${id}`);
  }
  createApiKey(body) {
    return this.api.post(`/admin/keys`, body);
  }
  // DASHBOARD STATS
  dashboardStats(_g) {
    var _h = _g, {
      merchantIds
    } = _h, params = __objRest(_h, [
      "merchantIds"
    ]);
    const headers = merchantIds && (merchantIds == null ? void 0 : merchantIds.length) > 0 ? {
      "X-Merchant-UUIDs": merchantIds
    } : void 0;
    return this.api.get("/admin/invoices/b2b/statistics", params, {
      headers
    });
  }
  dashboardChartExecutedAmount(params) {
    return this.api.get("/admin/stats/charts/executed_amount", params, {
      timeout: 20 * 1e3
    });
  }
  downloadPdfInvoice(uuid) {
    return this.api.get(
      `/admin/invoices/b2c/${uuid}/pdf_invoice`,
      {},
      { responseType: "blob" }
    );
  }
  getPaymentsB2C(uuid) {
    return this.api.get(`/admin/invoices/b2c/${uuid}/payments`);
  }
  getPaymentsB2B(uuid) {
    return this.api.get(`/admin/invoices/b2b/${uuid}/payments`);
  }
  getBackendNames() {
    return this.api.get(`/backends`);
  }
  getProviders(brand) {
    return this.api.get(`/payments/${brand}/providers`);
  }
  getPaymentBrands() {
    return this.api.get(`/payments/brands`);
  }
  updateProviderWeight(data) {
    return this.api.put(`/payments/${data.brand}/providers`, data.providers);
  }
  saveMerchantDisplayName(data) {
    return this.api.put(`/merchants/${data.merchantUuid}/display_name`, { display_name: data.name });
  }
}

export { AdminApi };
