var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class ApiError extends Error {
  constructor(errorObject, status) {
    super(ApiError.generateMessage(errorObject));
    __publicField(this, "errorObject");
    __publicField(this, "status");
    this.errorObject = errorObject;
    this.status = status != null ? status : 200;
    this.name = "ApiError";
  }
  static generateMessage(error) {
    let message = error;
    if (Array.isArray(error)) {
      message = error.join("; ");
    } else if (typeof error === "object" && error !== null) {
      message = Object.entries(error).map(([k, v]) => `${k.split("_").join(" ")} ${v}`).join("; ");
    }
    return message.toString();
  }
  get isMfaRequired() {
    const err = this.message.toLowerCase();
    return err === "MFA code is not present".toLowerCase() || err === "2FA code is not present".toLowerCase();
  }
  get isMfaInvalid() {
    const err = this.message.toLowerCase();
    return err === "2FA code is invalid".toLowerCase();
  }
  get isPageAccessDenied() {
    const err = this.message.toLowerCase();
    return err === "You are not authorized to access this page.".toLowerCase();
  }
  get shouldLogout() {
    return (this.status === 401 || this.status === 403) && !this.isMfaRequired && !this.isMfaInvalid && !this.isPageAccessDenied;
  }
  toString() {
    return this.message.toString();
  }
}

export { ApiError as default };
