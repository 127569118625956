import autoBind from 'auto-bind';
import ApiBase from '../core/ApiBase.js';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class FeeLevelsService {
  constructor(props) {
    __publicField(this, "apiBase");
    this.apiBase = new ApiBase(props);
    autoBind(this);
  }
  async getFeeLevel(id) {
    return this.apiBase.get(`/fee_levels/${id}`).then((res) => res.fee_level);
  }
  async getUserFeeLevels(userId) {
    return this.apiBase.get(`/users/${userId}/fee_levels`).then((res) => res.fee_levels);
  }
  async getUserCurrentFeeLevel(userId) {
    return this.apiBase.get(`/users/${userId}/current_fee`);
  }
}

export { FeeLevelsService as default };
