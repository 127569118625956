const notificationStatusesArr = [
  "created",
  // The invoice has been created and may be shown to the user.
  "executing",
  // The user has locked the payment amount and the invoice is ready to be paid.
  "executed",
  //Invoice has been successfully paid.
  "expired",
  //The invoice has expired without being paid.
  "failed",
  // The invoice has expired without being paid.
  "canceled"
];

export { notificationStatusesArr };
