import autoBind from 'auto-bind';

class MerchantApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  dashboardStats(params) {
    return this.api.get("/m/stats/", params);
  }
  dashboardChartExecutedAmount(params) {
    return this.api.get("/m/stats/charts/executed_amount", params, {
      timeout: 20 * 1e3
    });
  }
}

export { MerchantApi };
