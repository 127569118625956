import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { hasOwnPropertyCall } from '../helpers.js';
import { jsxs, jsx } from 'react/jsx-runtime';

const useOnErrorSnackbar = () => {
  const {
    enqueueSnackbar
  } = useSnackbar();
  return useCallback((err, secondaryText) => {
    if (!err || hasOwnPropertyCall(err, "silent")) return;
    const message = !secondaryText ? err.toString() : /* @__PURE__ */ jsxs("div", {
      children: [/* @__PURE__ */ jsx("div", {
        children: err.toString()
      }), /* @__PURE__ */ jsx("div", {
        style: {
          fontSize: "0.9em",
          opacity: 0.8
        },
        children: secondaryText
      })]
    });
    enqueueSnackbar(message, {
      key: err.toString(),
      variant: "error",
      preventDuplicate: true
    });
  }, [enqueueSnackbar]);
};

export { useOnErrorSnackbar };
