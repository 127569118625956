import autoBind from 'auto-bind';

class IntegrationApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  getIntegrations() {
    return this.api.get("/merchants/credentials");
  }
  createIntegration(payload) {
    return this.api.post(`/merchants`, payload);
  }
  toggleIntegration(payload) {
    return this.api.post(
      `/merchants/${payload.merchantUuid}/credentials/${payload.id}/state?disabled=${payload.disabled}`
    );
  }
  getFullCredentials(payload) {
    return this.api.get(
      `/merchants/${payload.merchantUuid}/credentials/${payload.id}`
    );
  }
  blockCustomer(payload) {
    return this.api.post("/merchants/customer/block", payload);
  }
  getBlockCustomers() {
    return this.api.get("/merchants/customer/block");
  }
  unblockCustomer(block_id) {
    return this.api.delete(`/merchants/customer/block/${block_id}`);
  }
  getMerchantCurrencies() {
    return this.api.get("/currencies");
  }
  updateMerchantCurrencies(data) {
    return this.api.put(
      `merchants/${data.merchantUuid}/credentials/${data.credentialsId}/currencies`,
      { currencies: data.currencies }
    );
  }
  getBackendNames() {
    return this.api.get(`/backends`);
  }
}

export { IntegrationApi };
