import autoBind from 'auto-bind';
import ApiBase from '../core/ApiBase.js';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class WebhooksService {
  constructor(props) {
    __publicField(this, "apiBase");
    this.apiBase = new ApiBase(props);
    autoBind(this);
  }
  async getWebhooks(params) {
    return this.apiBase.get(`/webhooks`, params);
  }
  async getWebhookInfo(id) {
    return this.apiBase.get(`/webhooks/${id}`).then((res) => res.webhook);
  }
  async getUserWebhooks(user_id, params) {
    return this.apiBase.get(`/users/${user_id}/webhooks`, params);
  }
}

export { WebhooksService as default };
