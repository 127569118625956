import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { parseInt } from 'lodash';
import { changePage, changeLimit } from '../../redux/pagination/pagination.actions.js';
import '@reduxjs/toolkit';
import { useSliceSelector } from './useSliceSelector.js';

function useReduxPagination(sliceName) {
  const { page, limit, limitOptions } = useSliceSelector(sliceName);
  const dispatch = useDispatch();
  const onPageChange = useCallback(
    (newPage) => dispatch(changePage(sliceName)(newPage)),
    [dispatch, sliceName]
  );
  const onLimitChange = useCallback(
    (newLimit) => dispatch(changeLimit(sliceName)(newLimit)),
    [dispatch, sliceName]
  );
  const gridPaginationProps = useMemo(
    () => ({
      page,
      onPageChange,
      pageSize: limit,
      onPageSizeChange: onLimitChange,
      rowsPerPageOptions: limitOptions,
      paginationMode: "server",
      pagination: true
    }),
    [limit, limitOptions, onLimitChange, page, onPageChange]
  );
  const tablePaginationProps = useMemo(
    () => ({
      page,
      onPageChange: (_, page2) => onPageChange(page2),
      rowsPerPage: limit,
      onRowsPerPageChange: (e) => onLimitChange(parseInt(e.target.value, 10)),
      rowsPerPageOptions: limitOptions
    }),
    [limit, limitOptions, onLimitChange, onPageChange, page]
  );
  const paginationApiParams = useMemo(
    () => ({
      pagesize: limit,
      page: page + 1
    }),
    [limit, page]
  );
  return {
    page,
    limit,
    onPageChange,
    onLimitChange,
    paginationApiParams,
    gridPaginationProps,
    tablePaginationProps
  };
}

export { useReduxPagination };
