import { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { useSliceSelector } from './useSliceSelector.js';
import { updateOrganizations, changeFilterItems, clearSearchValue } from '../../redux/filter/filter.actions.js';
import '@reduxjs/toolkit';
import { findItemIndex } from '../../redux/filter/filter.utils.js';
import { formatLocalDateRange } from '../../formatters/dateFormatters.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const mapParams = {
  source_currency: "source_currency",
  destination_currency: "destination_currency"
};
const generateFilterApiParams = (filterItems, organizations) => {
  let filters = filterItems == null ? void 0 : filterItems.reduce((acc, item) => {
    let key = item.field;
    let value = item.value;
    if (item.operator === "is") {
      return acc;
    }
    if (item.operator === "onOrAfter") {
      key = `${item.field}_from`;
      value = formatLocalDateRange(moment(item.value), "from");
    }
    if (item.operator === "onOrBefore") {
      key = `${item.field}_to`;
      value = formatLocalDateRange(moment(item.value), "to");
    }
    return item.value !== "" && item.value !== void 0 ? __spreadProps(__spreadValues({}, acc), {
      // @ts-ignore
      [mapParams[key] || key]: value
    }) : acc;
  }, {});
  if (organizations) {
    filters = __spreadProps(__spreadValues({}, filters), {
      merchantIds: organizations.join(",")
    });
  }
  const isOnFilterExists = filterItems.find((item) => item.operator === "is");
  if (isOnFilterExists) {
    return __spreadProps(__spreadValues({}, filters), {
      created_at_from: formatLocalDateRange(moment(isOnFilterExists.value), "from"),
      created_at_to: formatLocalDateRange(moment(isOnFilterExists.value), "to")
    });
  }
  return filters;
};
function useReduxFilter(sliceName) {
  const dispatch = useDispatch();
  const {
    filterItems: reduxFilterItems = [],
    organizations: reduxOrganizations = [],
    searchValue: reduxSearchValue = ""
  } = useSliceSelector(sliceName);
  const [filterItems, setFilterItems] = useState(reduxFilterItems);
  const [organizations, setOrganizations] = useState(reduxOrganizations);
  const [searchValue, setSearchValue] = useState(reduxSearchValue);
  const toggleOrganization = useCallback(
    (ids) => {
      setOrganizations(ids);
      setFilterApiParams(generateFilterApiParams(reduxFilterItems, ids));
      dispatch(updateOrganizations(sliceName)(ids));
    },
    [dispatch, reduxFilterItems, sliceName]
  );
  const [filterApiParams, setFilterApiParams] = useState(
    generateFilterApiParams(reduxFilterItems, reduxOrganizations)
  );
  const openFilter = useCallback(() => {
    dispatch(changeFilterItems(sliceName)(reduxFilterItems));
    setFilterItems(reduxFilterItems);
    setFilterApiParams(generateFilterApiParams(reduxFilterItems, reduxOrganizations));
  }, [sliceName, dispatch, reduxFilterItems, reduxOrganizations]);
  const saveFilter = useCallback(() => {
    dispatch(changeFilterItems(sliceName)(filterItems));
    setFilterItems(filterItems);
    setFilterApiParams(generateFilterApiParams(filterItems, organizations));
  }, [sliceName, dispatch, filterItems, organizations]);
  const addNewFilter = useCallback(
    (newItem) => {
      var _a, _b;
      setFilterItems([
        ...filterItems,
        __spreadProps(__spreadValues({}, newItem), { id: (_a = newItem.id) != null ? _a : v4(), value: (_b = newItem.value) != null ? _b : void 0 })
      ]);
    },
    [filterItems]
  );
  const updateFilter = useCallback(
    (updatedItem) => {
      const index = findItemIndex(filterItems, updatedItem);
      if (index === void 0) return;
      const newItems = filterItems.map((f, i) => i === index ? updatedItem : f);
      setFilterItems(newItems);
    },
    [filterItems]
  );
  const deleteFilter = useCallback(
    (deleteItem, removeAll) => {
      if (removeAll) {
        removeAllFilterItems();
        return;
      }
      const index = findItemIndex(filterItems, deleteItem);
      if (index === void 0) return;
      const newItems = filterItems.filter((f, i) => i !== index);
      setFilterItems(newItems);
      if (newItems.length === 0) {
        removeAllFilterItems();
      }
    },
    [dispatch, sliceName, filterItems, reduxOrganizations, reduxSearchValue]
  );
  const updateSearchValue = useCallback((value) => {
    setSearchValue(value);
  }, []);
  const removeAllFilterItems = () => {
    const newItems = [];
    setSearchValue("");
    setOrganizations([]);
    setFilterApiParams(generateFilterApiParams(newItems, []));
    setFilterItems(newItems);
    dispatch(changeFilterItems(sliceName)(newItems));
    dispatch(updateOrganizations(sliceName)([]));
    dispatch(clearSearchValue(sliceName)());
  };
  const onFilterModelChange = (item) => {
    console.log("onFilterModelChange item", item);
  };
  const gridFilterProps = useMemo(
    () => ({
      filterModel: { items: filterItems.map((item) => mapCustomItemToGrid(item)) },
      onFilterModelChange: (model) => onFilterModelChange(model),
      filterMode: "server"
    }),
    [filterItems]
  );
  return {
    appliedFilterItems: reduxFilterItems,
    filterItems,
    gridFilterProps,
    addNewFilter,
    updateFilter,
    deleteFilter,
    saveFilter,
    openFilter,
    filterApiParams,
    toggleOrganization,
    organizations,
    updateSearchValue,
    searchValue
  };
}
const mapGridItemToCustom = (item) => {
  var _a, _b;
  return {
    operator: item.operatorValue,
    id: (_a = item.id) != null ? _a : "",
    field: (_b = item.columnField) != null ? _b : "",
    value: item.value
  };
};
const mapCustomItemToGrid = (item) => ({
  columnField: item.field,
  value: item.value,
  id: item.id,
  operatorValue: item.operator
});

export { mapCustomItemToGrid, mapGridItemToCustom, useReduxFilter };
