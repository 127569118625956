import autoBind from 'auto-bind';
import ApiBase from '../core/ApiBase.js';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class DashboardsService {
  constructor(props) {
    __publicField(this, "apiBase");
    this.apiBase = new ApiBase(props);
    autoBind(this);
  }
  async getAdminDashboard(params) {
    return this.apiBase.get(`/admins/dashboard`, params);
  }
  async getUserDashboard(userId, params) {
    return this.apiBase.get(`/users/${userId}/dashboard`, params);
  }
}

export { DashboardsService as default };
